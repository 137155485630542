import * as React from 'react';
import {
  Box,
} from '@chakra-ui/react';
import AppTemplate from '../components/AppTemplate';
import Hero from '../components/BlogSections/BlogHero';
import { BlogContent } from '../components/BlogSections/BlogContent';

// markup
function BlogList({ pageContext: data }: any) {
  return (
    <AppTemplate
      navigation={data.navigation}
      SEO={{
        title: data.title,
        description: data.metaDescription.metaDescription,
        author: data.author.map((i: any) => i.name),
        imageUrl: data.thumbnail.file.url,
      }}
    >
      <Hero
        title={data.title}
        date={new Date(data.createdAt)}
        authors={data.author.map((i: any) => i.name)}
      />
      <BlogContent content={data.content} imageUrl={data.thumbnail.file.url} />
      <Box
        sx={{
          background: 'linear-gradient(0deg, var(--chakra-colors-gray-50) 0%, rgba(237,243,248,1) 100%)',
          height: '45px',
          width: '100%',
        }}
      />
    </AppTemplate>
  );
}

export default BlogList;

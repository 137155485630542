import { Link } from '@chakra-ui/react';
import React from 'react';
import { Link as GatsbyLink } from 'gatsby';

function isExternal(url: string) {
  const domain = (url2: string) => url2.replace('http://', '').replace('https://', '').split('/')[0];
  // eslint-disable-next-line no-restricted-globals
  return domain((typeof window !== 'undefined' ? window.location : { href: '/' }).href) !== domain(url);
}

export default function MixedLink(props: any) {
  if (!props) {
    return (
      <Link
        as="a"
        href="#unknown"
      />
    );
  }
  const link = props.to;
  const newProps = {
    ...props,
    to: undefined,
  };
  if (isExternal(link)) {
    if (newProps.setExternal) newProps.setExternal(true);
    return (
      <Link
        as="a"
        href={link}
        {...newProps}
      />
    );
  }
  return (
    <Link
      as={GatsbyLink}
      to={link}
      {...newProps}
    />
  );
}

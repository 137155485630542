import React, { useState } from 'react';
import { ContentfulRichTextGatsbyReference, renderRichText, RenderRichTextData } from 'gatsby-source-contentful/rich-text';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import {
  Flex, Text, Heading, Box, Center, Image,
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import MixedLink from '../MixedLink';

function Hyperlink(node: any, children: any) {
  const { uri } = node.data;
  const [external, setExternal] = useState(false);
  return (
    <>
      <MixedLink
        to={uri}
        setExternal={setExternal}
        sx={{
          textDecoration: 'underline',
          ':hover': {
            color: 'green',
          },
        }}
      >
        {children}
      </MixedLink>
      {' '}
      { external && <ExternalLinkIcon /> }
    </>
  );
}

function Paragraph(node: any, children: any) {
  return (
    <Text fontSize="xl">
      {children}
      <br />
    </Text>
  );
}

function HeadingRender(heading: number) {
  // eslint-disable-next-line func-names
  return function (node: any, children: any) {
    return (
      <Heading order={heading}>
        {children}
      </Heading>
    );
  };
}

export function BlogContent({ content, imageUrl }: {
  content: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  imageUrl: string;
}) {
  return (
    <>
      <Box
        sx={{
          background: 'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(237,243,248,1) 100%)',
          height: '75px',
          width: '100%',
        }}
        pos="relative"
        zIndex={-1}
        top={-75}
        marginBottom={-75}
      />
      <Box sx={{ backgroundColor: 'rgba(237,243,248,1)' }}>
        <Center>
          <Image
            borderRadius="10px"
            width={{
              base: '90vw',
              md: '80vw',
              lg: '70vw',
              xl: '40vw',
            }}
            src={`${imageUrl}?fm=webp`}
            alt="Blogipostauksen kuva"
            mt={10}
          />
        </Center>

        <Flex justifyContent="center" pt={5} pb={20}>
          <Box width={{
            base: '90vw',
            md: '80vw',
            lg: '70vw',
            xl: '40vw',
          }}
          >
            {renderRichText(content, {
              renderNode: {
                [INLINES.ENTRY_HYPERLINK]: Hyperlink,
                [INLINES.HYPERLINK]: Hyperlink,
                [BLOCKS.PARAGRAPH]: Paragraph,
                [BLOCKS.HEADING_1]: HeadingRender(1),
                [BLOCKS.HEADING_2]: HeadingRender(2),
                [BLOCKS.HEADING_3]: HeadingRender(3),
                [BLOCKS.HEADING_4]: HeadingRender(4),
                [BLOCKS.HEADING_5]: HeadingRender(5),
                [BLOCKS.HEADING_6]: HeadingRender(6),
              },
            })}
          </Box>

        </Flex>
      </Box>
    </>
  );
}

import React from 'react';
import {
  Box,
  Button,
  Center,
  Heading,
} from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { BlogAuthor } from './BlogPost';

export default function Hero({
  title, date, authors,
}: {
  title: string,
  date: Date,
  authors: string[],
}) {
  return (
    <Box
      px={8}
      pt={24}
      mx="auto"
    >
      <Box
        w={{
          base: 'full',
          md: 11 / 12,
          xl: 9 / 12,
        }}
        mx="auto"
        textAlign="center"
      >
        <Button
          as={GatsbyLink}
          to="/blogi"
          colorScheme="accesibleButton"
          variant="link"
          style={{ textDecoration: 'none' }}
          mb={5}
        >
          <ArrowBackIcon />
          {' '}
          Palaa takaisin
        </Button>
        <Heading
          as="h1"
          mb={6}
          fontSize={{
            base: '4xl',
            md: '6xl',
          }}
          fontWeight="bold"
          lineHeight="none"
          letterSpacing={{
            base: 'normal',
            md: 'tight',
          }}
          color="gray.900"
          _dark={{
            color: 'gray.100',
          }}
        >
          {title}
        </Heading>
        <Center>
          <BlogAuthor
            name={[authors.slice(0, -1).join(', '), authors.slice(-1)[0]].join(authors.length < 2 ? '' : ' ja ')}
            date={date}
          />
        </Center>
      </Box>
    </Box>
  );
}
